// SCSS BY HUGO CHESNEL

// Font Import
@font-face {
    font-family : 'Brittany';
    src : url('./fonts/brittany/Brittany.ttf') format('truetype')
}

// Color set
$primary-color : #174365;
$second-color : #a2e8d8;
$special-font : 'Brittany';
$main-font : 'Montserrat';

// Initializing code
* {
    margin: 0;
    padding: 0;
    font-family: $main-font;
}

html, body {
    width: 100%;
    scroll-behavior: smooth;
}
#valerieNext {
    background-color: #C17B94;
}
#valerie {
    background-color: #d5b7bc;
}
#CwSReact {
    background: linear-gradient(158deg, $primary-color, $second-color);
}
#ohMyFood, #kasa, #booki, #sophieBluel, #printIt, #argentBank, #p724Events {
    background-color: rgb(229,229,229);
}
#CwSV1 {
    background-color: $primary-color;
}
#ridingCities {
    background-color: rgb(25,36,40);
}
#menuMaker {
    background-color: rgb(19,19,19);
}
#ninaCarducci {
    background-color: rgb(243,199,169);
}