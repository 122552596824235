@import '../../assets/styles/index';

.projet {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 3;
    &__ctn {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        background-color: #f3f4f5;
        &--content {
            width: 60%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 30px;
            &--description {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: lighten($primary-color, 10%);
                gap: 30px;
                &P {
                    display: flex;
                    flex-direction: column;
                    color: lighten($primary-color, 15%);
                    padding: 20px;
                    background-color: darken(#f3f4f5, 2%);
                    box-shadow: 4px 3px 2px darken(#f3f4f5, 4%);
                    border-radius: 20px;
                    & p a {
                        color: inherit;
                        background-color: inherit;
                        padding: 0;
                        font-weight: bold;
                        text-decoration: underline;
                        &:hover {
                            animation: none;
                        }
                    }
                }
            }
            &--interDiv {
                margin: auto;
                width: 40%;
                height: 3px;
                border-radius: 5px;
                background: linear-gradient(158deg, #174365, #a2e8d8);
            }
            &--skills {
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 100%;
                gap: 50px;
                flex-direction: column;
                & h3 {
                    color: lighten($primary-color, 10%);
                }
                &Ctn {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 50px;
                    max-width: 100%;
                    &Item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;
                        font-size: 14px;
                        color: lighten($primary-color, 15%);
                        & img {
                            width: 28px;
                            height: 28px;
                        }
                        //Desactivating unavailable feature
                        &Unavailable {
                            display: none;
                        }
                        & p {
                            width: 58px;
                            text-align: center;
                        }
                    }
                }
            }
            &--title {
                font-size: 25px;
                text-align: center;
                color: $primary-color;
                & span {
                    font-size: 20px;
                    color: lighten($primary-color, 15%)
                }
            }
        }
    }
    & a {
        padding: 10px;
        background-color: lighten($primary-color, 10%);
        border-radius: 10px;
        text-decoration: none;
        font-weight: bold;
        color: #f3f4f5;
        text-align: center;
        &:hover {
            animation: workContactHover 0.5s;
            @keyframes workContactHover {
                0% {
                    padding: 10px;
                }
                50% {
                    padding-left: 12px;
                    padding-right: 12px;
                    background-color: lighten($primary-color, 25%);
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .projet__ctn--content {
        width: 80%;
    }
}