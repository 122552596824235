.error {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 4;
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        background-color: #f3f4f5;
        padding: 20px 0;
        & h2 {
            font-size: 100px;
        }
    }
}