@import '../../assets/styles/index';

.presentation__content--portfolioWork {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    background-color: darken(#f3f4f5, 2%);
    box-shadow: 4px 3px 2px darken(#f3f4f5, 4%);
    padding: 15px;
    border-radius: 10px;
    &Img {
        width: 55%;
        padding: 5px;
        border-radius: 10px;
        display: flex;
        & img {
            width: 100%;
            border-radius: 7px;
        }
    }
    &Text {
        display: flex;
        width: 45%;
        gap: 10px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        & h2 {
            text-align: center;
            & span {
                font-size: 14px;
            }
        }
        & p {
            text-align: justify;
        }
        & a {
            padding: 10px;
            background-color: lighten($primary-color, 10%);
            border-radius: 10px;
            text-decoration: none;
            color: #f3f4f5;
            text-align: center;
            & i {
                font-size: 30px;
            }
            &:hover {
                animation: workContactHover 0.5s;
                @keyframes workContactHover {
                    0% {
                        padding: 10px;
                    }
                    50% {
                        padding-left: 12px;
                        padding-right: 12px;
                        background-color: lighten($primary-color, 25%);
                    }
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .presentation__content--portfolioWork {
        flex-direction: column;
        align-items: center;
        &Img {
            width: 80%;
        }
        &Text {
            width: 80%;
        }
    }
}

@media (max-width: 768px) {
    .presentation__content--portfolioWork {
        width: 80%;
        &Img {
            width: 95%;
        }
        &Text {
            width: 95%;
        }
    }   
}