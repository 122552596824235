@import '../../assets/styles/index';

.portfolio__content--ctn a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 175px;
    position: relative;
    overflow: hidden;
    z-index: 3;
    border-radius: 5px;
    &:hover {
        & .portfolio__content--ctn--itemText {
            display: flex;
        }
    }
}
.portfolio__content--ctn--item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    &Img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        & img{
            object-fit: cover;
            max-height: 95%;
            max-width: 90%;
            border-radius: 4px;
        }
    }
    &Text {
        display: none;
        position: absolute;
        flex-direction: column;
        font-size: 10px;
        width: 100%;
        bottom: 0;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        border-radius: 5px 5px 0 0;
        background: lighten($primary-color, 15%);
        color: #f3f4f5;
    }
}

@media (max-width: 768px) {
    .portfolio__content--ctn a {
        width: 100%;
        height: auto;
    }
    .portfolio__content--ctn--itemImg {
        align-items: flex-start;
        padding: 10% 0 15% 0;
    }
    .portfolio__content--ctn--itemText {
        display : flex
    }
}