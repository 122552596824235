@import '../../assets/styles/index';

.projects {
    width: 100%;
    height: 115%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.portfolio {
    background-color: #f3f4f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__content {
        width: 80%;
        height: 95%;
        display: flex;
        flex-direction: column;
        padding: 40px 0;
        gap: 40px;
        align-items: center;
        justify-content: center;
        color: lighten($primary-color, 15%);
        &--title {
            font-size: 25px;
            text-align: center;
            color: $primary-color;
            & span {
                font-size: 15px;
                color: lighten($primary-color, 15%)
            }
        }
        & #returnHome {
            padding: 10px 15px;
            background-color: lighten($primary-color, 10%);
            border-radius: 10px;
            text-decoration: none;
            color: #f3f4f5;
            text-align: center;
            z-index: 3;
            &:hover {
                animation: returnHomeHover 0.5s;
                @keyframes returnHomeHover {
                    0% {
                        padding: 10px 15px;
                    }
                    50% {
                        padding: 10px 18px ;
                        background-color: lighten($primary-color, 25%);
                    }
                }
            }
        }
        &--ctn {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 40px;
        }
    }
}