.fade {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    overflow: hidden;
    width: 100%;
    &__wave {
        position: absolute;
        width: 100%;
        bottom: 99.9%;
    }
}

.error .fade__wave {
    bottom: 99%;
}

@media (min-width: 2100px) {
    main .fade {
        height: 3%;
    } 
}