@import '../../assets/styles/index';

.footer {
    background-color: $primary-color;
    width: 100%;
    height: 300px;
    color: #f3f4f5;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    // Fade wave between content and footer
    &__wave {
        position: absolute;
        top: -0.5%;
        width: 100%;
        height: 100px;
        &--img {
            width: 100%;
            height: 100%;
        }
    }
    &__text {
        padding: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        &--nav {
            display: flex;
            gap: 10px;
            & a {
                text-decoration: none;
                color: inherit;
                & p {
                    font-size: 20px;
                    text-decoration: underline;
                }
            }
        }
        &--btnLegals {
            background-color: inherit;
            border: none;
            color: inherit;
            font-size: inherit;
            text-decoration: underline;
            cursor: pointer;
        }
        & a {
            color: #f3f4f5;
        }
    }
}

@media (max-width: 1024px) {
    .footer .footer__text {
		 font-size: 12px;
         padding-bottom: 70px;
    }
    .footer .footer__text--nav a p {
		 font-size: 12px;
	}
}

@media (max-width: 768px) {  
    .footer .footer__wave {
        height: auto;
    }
}