@import '../../assets/styles/index';

.nav {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    height: 100%;
    & .navbar {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        gap: 15px;
        left: 1em;
        height: 55%;
        width: 33px;
        background-color: darken($primary-color, 4%);
        box-shadow: 4px 3px 2px darken($primary-color, 7%);
        border-radius: 15px;
        opacity: 0.96;
        &__element {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #f3f4f5;
            width: 30px;
            height: 30px;
            &--name {
                display: none;
                font-size: 10px;
                background-color: darken($primary-color, 4%);
                padding: 10px;
                border-radius: 20px;
                position: absolute;
                color: #f3f4f5;
                text-align: center;
            }
            &:hover {
                color: darken($primary-color, 4%);
                background-color: #f3f4f5;
                border-radius: 50px;
                & .navbar__element--name {
                    display: block;
                    left: 40px;
                }
            }
            & a {
                color: inherit;
                text-decoration: none;
            }
        }
    }
    & .navbarTiny {
        display: none;
        position: fixed;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        gap: 15px;
        width: 100%;
        height: 4em;
        bottom: 0;
        background-color: darken($primary-color, 4%);
        box-shadow: 4px 3px 2px darken($primary-color, 7%);
        &__element {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #f3f4f5;
            width: 30px;
            height: 30px;
            gap: 5px;
            &--name {
                font-size: 10px;
                text-align: center;
            }
            & a {
                color: inherit;
                text-decoration: none;
            }
        }
        
    }
}

@media (max-width: 1024px) {
    .nav {
        height: 0%;
        width: 100%;
    }
    .nav .navbar {
        display: none;
    }
    .nav .navbarTiny {
        display: flex;
    }
}

@media (max-width: 530px) {
    .nav .navbarTiny__element--name {
        display: none;
    }
}