.img {
    position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 1;
        // Different waves in top corners
        &__waveRoll {
            &--1 {
                position: absolute;
                right: -220px;
                top: -350px;
            }
        }
        &__wave1 {
            position: absolute;
            top: -300px;
            left: -150px;

        }
        &__wave3 {
            position: absolute;
            top: -150px;
            left: -150px;
                
        }
}

@media (max-width: 768px) {  
    .img__waveRoll--1 {
		 transform: scale(60%);
	}
    .img__wave1 {
		 transform: scale(60%) translate(-20%);
	}
    .img__wave3 {
		 transform: scale(60%) translate(-30%, -30px);
	}
}

@media (max-width: 425px) {
    .img__wave1 {
		 transform: scale(40%) translate(-30%);
	}
    .img__wave3 {
		 transform: scale(45%) translate(-40%, -20%);
	}
}

@media (max-width: 370px) {
    .img__wave1 {
        transform: scale(20%) translate(-100%, 30%);
   }
    .img__wave3 {
        transform: scale(25%) translate(-80%, -55%);
   }
}

@media (max-width: 300px) {
    .img__wave1 {
        transform: scale(20%) translate(-100%, 20%);
   }
    .img__wave3 {
        transform: scale(25%) translate(-80%, -70%);
   }
}