@import '../../assets/styles/index';

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    overflow: hidden;
    width: 100%;
    background: linear-gradient(158deg, $primary-color, $second-color); 
}