@import '../../../assets/styles/index';

.waiter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 150px;
    text-align: center;
    gap: 10px;
    &__text {
        font-family: inherit;
        color: white;
        max-width: 100%;
        z-index: 2;
        // Logo style
        &--logo {
            font-size: 7em;
            padding-bottom: 20px;
            & a {
                font-family: $special-font;
                color: inherit;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 768px) {
    .waiter {
        padding: 110px;
        &__text--logo {
            font-size: 4em;
        }
    }
}